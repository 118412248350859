
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class LayerLandImpact extends Vue {
    
    @Prop({
        default: () => []
    }) currentLandImpacts: OM.LandImpactPerMaterial[];

}
