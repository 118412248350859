
import { ElectricityEmissionClient, EstimationClient, FabricEstimationClient, TransportDistanceCalculationClient, TransportTypeConfigurationClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import RawMaterialList from '../../components/rawMaterialList.vue';
import EditLayerTransportation from '../../components/editLayerTransportation.vue';
import EditFabricLayerModal from './modals/editFabricLayerModal.vue';
import LayerWaterFootprint from '../../components/layerWaterFootprint.vue';
import LayerLandImpact from '../../components/layerLandImpact.vue';
import store from '@/store';
import * as OM from '@/Model';

@Options({
    components: {
        RawMaterialList,
        EditLayerTransportation,
        LayerWaterFootprint,
        LayerLandImpact
    }
})
export default class RawMaterials extends Vue {

    estimationIdentifier: string = "";

    estimationBaseInformation: OM.EstimationBaseInformationVM = new OM.EstimationBaseInformationVM();

    layers: OM.LayerComposition[] = [];

    electricityCountries: OM.TextIdentifier[] = [];
    waterFootprints: { [key: string]: OM.WaterFootprintPerMaterial[]; } = {};
    landImpacts: { [key: string]: OM.LandImpactPerMaterial[]; } = {};
    rawMaterialTransportPhases: { [key: string]: OM.TransportProductionPhase[]; } = {};
    transportationCountries: OM.TransportDistanceCalculationVM[] = [];
    transportTypes: string[] = [];
    rawMaterials: OM.RawMaterialListVM[] = [];

    loaded: boolean = false;

    created() {
        this.estimationIdentifier = this.$route.params.estimationIdentifier.toString();
        this.init();
    }

    init() {
        FabricEstimationClient.createLayerIfNotExist(this.estimationIdentifier)
        .then(x => {
            Promise.all([
                FabricEstimationClient.getBaseInformation(this.estimationIdentifier),
                FabricEstimationClient.getEstimationsGarmentLayers(this.estimationIdentifier, null, false),
                FabricEstimationClient.getRawMaterialTransportProductionPhases(this.estimationIdentifier),
                FabricEstimationClient.getWaterFootprints(this.estimationIdentifier),
                FabricEstimationClient.getLandImpacts(this.estimationIdentifier), 
                ElectricityEmissionClient.getCountries(true),
                TransportDistanceCalculationClient.getAllConfigurations(),
                TransportTypeConfigurationClient.getAllTransportTypes(),
                FabricEstimationClient.getEstimationRawMaterials(this.estimationIdentifier)
            ])
            .then( xs => {
                this.estimationBaseInformation = xs[0];
                this.layers = xs[1];
                this.rawMaterialTransportPhases = xs[2];
                this.waterFootprints = xs[3];
                this.landImpacts = xs[4];
    
                this.electricityCountries = xs[5];
                this.transportationCountries = xs[6];
                this.transportTypes = xs[7].map(x => x.transportType);
    
                var suggestedSqmFixedNumber = this.estimationBaseInformation.totalSuggestedSQM.toFixed(3);
                this.estimationBaseInformation.totalSuggestedSQM = Number.parseFloat(suggestedSqmFixedNumber);

                this.rawMaterials = xs[8];
    
                this.loaded = true;
            })
        })
    }

    async editLayer(model: OM.LayerComposition, index: number){
        var editModel = new OM.EditEstimationsGarmentLayersVM();
        editModel.index = index;
        editModel.isFabric = true;
        
        if(model) {
            editModel.layerName = model.layerName;
            editModel.sqm = model.sqm;
            editModel.grams = model.grams;
            editModel.fabrics = model.fabricCompositions;
        }

        this.$opModal.show(EditFabricLayerModal, {
            estimationIdentifier: this.estimationIdentifier,
            rawMaterials: this.rawMaterials,
            suggestedSQM: this.layers[0].sqm,
            isFirstLayer: true,
            editModel: editModel,
            saveToGarmentConfiguration: false,

            saveCallback: async (layer: OM.EditEstimationsGarmentLayersVM) => {
                var editEstimationGarmentRis = await store.state.estimationClient.editEstimationsGarmentLayers(layer);
                this.$opModal.closeAll();

                store.state.estimationClient.precompileAllTransportationAndProcessesPhases(this.estimationIdentifier)
                .then(x => {
                    this.init();
                })
            }
        })
    }

    saveTransportationCallback(editLayerTransportationVM : OM.EditLayerTransportationVM){
        FabricEstimationClient.editRawMaterialTransportation(editLayerTransportationVM)
        .then( x => {
            this.$opModal.closeAll();
            this.init();
        })
    }

    removeCallback(item: OM.RemoveLayerTransportationVM){
        FabricEstimationClient.removeRawMaterialTransportation(item)
        .then(x => {
            this.$opModal.closeAll();
            this.init();
        })
    }

    get allTransportSetted() {
        var disabledCount = 0;

        this.layers.forEach(layer => {
            layer.fabricCompositions.forEach(fabric => {
                if(this.rawMaterialTransportPhases[layer.layerName].filter(x => x.rawMaterial.identifier == fabric.rawMaterial.identifier).length == 0)
                    disabledCount = disabledCount + 1;
            });
        });

        return disabledCount == 0;
    }
    get disabled(){
        return !this.estimationBaseInformation.totalSQM || this.layers.length == 0 ||
            this.layers.filter(x => x.fabricCompositions.length == 0).length > 0 || !this.allTransportSetted;
    }

    next() {
        EstimationClient.initializeAllProcessesByRawMaterialsAndProductionPhase(this.estimationIdentifier, this.$store.state.phases[0].identifier)
        .then(x => {            
            var firstProcess = this.$store.state.phases[0];
            this.$router.push("/fabric-calculator/productionprocess/" + this.estimationIdentifier + "/" + firstProcess.text);
        })
    }

}
